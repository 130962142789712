import { Center, CenterProps } from '@mantine/core';
import { useIsDarkMode } from 'hooks/useIsDarkMode';
import defReversed from 'images/logos/default-reversed.png';
import def from 'images/logos/default.png';
import Image, { StaticImageData } from 'next/image';
import { logos, reversedLogos } from './logos';

export function getLogo(
  assetKey: string,
  isDarkMode: boolean,
  noDefault = false,
): StaticImageData {
  const defaultLogo = isDarkMode ? defReversed : def;

  if (!assetKey) return defaultLogo;

  const logo = logos[assetKey];
  const reversedLogo = reversedLogos[assetKey];

  if (isDarkMode && reversedLogo) {
    return reversedLogo;
  }

  if (noDefault) {
    return logo ?? undefined;
  }

  return logo || defaultLogo;
}

export interface Props extends CenterProps {
  assetKey?: string;
  size?: number;
}

export const AssetLogo = ({
  assetKey,
  size = 24,
  className,
  ...others
}: Props) => {
  const isDarkMode = useIsDarkMode();
  const imageScr = getLogo(assetKey as string, isDarkMode);

  return (
    <Center className={className} w={size} h={size} {...others}>
      <Image
        style={{ borderRadius: '50%' }}
        height={size}
        width={size}
        src={imageScr}
        alt={assetKey || ''}
      />
    </Center>
  );
};
