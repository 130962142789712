import {
  aca,
  ampe,
  apillon,
  aseed,
  astr,
  auq,
  bnc,
  cfg,
  crab,
  csm,
  dai,
  ded,
  dev,
  dot,
  eq,
  eqd,
  fil,
  glmr,
  hdx,
  ibtc,
  intr,
  kar,
  kbtc,
  kint,
  kma,
  ksm,
  ldot,
  lit,
  manta,
  mgx,
  movr,
  neuro,
  nodl,
  otp,
  para,
  paring,
  peaq,
  pen,
  pha,
  pica,
  pink,
  ring,
  rmrk,
  sdn,
  stink,
  sub,
  teer,
  tnkr,
  tt1,
  tur,
  unit,
  usdc,
  usdcwh,
  usdt,
  usdtwh,
  vastr,
  vbnc,
  vdot,
  vfil,
  vglmr,
  vksm,
  vmanta,
  vmovr,
  wbtc,
  weth,
  wftm,
  wifd,
  xrt,
  ztg,
} from '@moonbeam-network/xcm-config';
import { StaticImageData } from 'next/image';

import acaLogo from 'images/logos/aca.png';
import ampeLogo from 'images/logos/ampe.png';
import apillonLogo from 'images/logos/apillon.png';
import aseedLogo from 'images/logos/aseed.svg';
import astrLogo from 'images/logos/astr.png';
import bncLogo from 'images/logos/bnc.png';
import cfgReversedLogo from 'images/logos/cfg-reversed.png';
import cfgLogo from 'images/logos/cfg.png';
import crabLogo from 'images/logos/crab.png';
import csmLogo from 'images/logos/csm.png';
import daiLogo from 'images/logos/dai.png';
import dedLogo from 'images/logos/ded.png';
import dotLogo from 'images/logos/dot.png';
import eqLogo from 'images/logos/eq.png';
import eqdReversedLogo from 'images/logos/eqd-reversed.svg';
import eqdLogo from 'images/logos/eqd.svg';
import ftm from 'images/logos/fantom-ftm-logo.png';
import filLogo from 'images/logos/fil.png';
import hdxLogo from 'images/logos/hdx.png';
import ibtcReversedLogo from 'images/logos/ibtc-reversed.svg';
import ibtcLogo from 'images/logos/ibtc.svg';
import intrReversedLogo from 'images/logos/intr-reversed.svg';
import intrLogo from 'images/logos/intr.svg';
import karLogo from 'images/logos/kar.png';
import kbtcReversedLogo from 'images/logos/kbtc-reversed.svg';
import kbtcLogo from 'images/logos/kbtc.png';
import kintReversedLogo from 'images/logos/kint-reversed.svg';
import kintLogo from 'images/logos/kint.png';
import kmaLogo from 'images/logos/kma.png';
import ksmLogo from 'images/logos/ksm.png';
import litLogo from 'images/logos/litmus.png';
import mantaLogo from 'images/logos/manta.png';
import mgxLogo from 'images/logos/mgx.png';
import neuroLogo from 'images/logos/neuro.png';
import nodlLogo from 'images/logos/nodl.png';
import otpLogo from 'images/logos/otp.png';
import paraLogo from 'images/logos/para.svg';
import paringLogo from 'images/logos/paring.png';
import peaqLogo from 'images/logos/peaq.png';
import penLogo from 'images/logos/pen.png';
import phaLogo from 'images/logos/pha.png';
import picaLogo from 'images/logos/pica.png';
import pinkLogo from 'images/logos/pink.png';
import ringLogo from 'images/logos/ring.svg';
import rmrkLogo from 'images/logos/rmrk.png';
import sdnLogo from 'images/logos/sdn.png';
import stinkLogo from 'images/logos/stink.png';
import subLogo from 'images/logos/sub.png';
import teerLogo from 'images/logos/teer.png';
import tnkrLogo from 'images/logos/tnkr.svg';
import tt1Logo from 'images/logos/tt1.svg';
import turLogo from 'images/logos/tur.png';
import unqLogo from 'images/logos/unq.svg';
import usdcLogo from 'images/logos/usdc.png';
import usdtLogo from 'images/logos/usdt.png';
import wbtcLogo from 'images/logos/wbtc.png';
import wethLogo from 'images/logos/weth.png';
import wifdLogo from 'images/logos/wifd.png';
import xrtLogo from 'images/logos/xrt.svg';
import ztgLogo from 'images/logos/ztg.svg';
import moonbaseLogo from 'images/moonbase-icon.webp';
import moonbeamLogo from 'images/moonbeam-icon-new.svg';
import moonriverLogo from 'images/moonriver-icon-new.svg';

export const logos: Record<string, StaticImageData> = {
  [aca.key]: acaLogo,
  [ampe.key]: ampeLogo,
  [apillon.key]: apillonLogo,
  [astr.key]: astrLogo,
  [aseed.key]: aseedLogo,
  [auq.key]: unqLogo,
  [bnc.key]: bncLogo,
  [cfg.key]: cfgLogo,
  [crab.key]: crabLogo,
  [csm.key]: csmLogo,
  [dai.key]: daiLogo,
  [ded.key]: dedLogo,
  [dev.key]: moonbaseLogo,
  [dot.key]: dotLogo,
  [eq.key]: eqLogo,
  [eqd.key]: eqdLogo,
  [fil.key]: filLogo,
  [glmr.key]: moonbeamLogo,
  [hdx.key]: hdxLogo,
  [ibtc.key]: ibtcLogo,
  [intr.key]: intrLogo,
  [kar.key]: karLogo,
  [kbtc.key]: kbtcLogo,
  [kint.key]: kintLogo,
  [kma.key]: kmaLogo,
  [ksm.key]: ksmLogo,
  [ldot.key]: dotLogo,
  [lit.key]: litLogo,
  [manta.key]: mantaLogo,
  [mgx.key]: mgxLogo,
  [movr.key]: moonriverLogo,
  [nodl.key]: nodlLogo,
  [neuro.key]: neuroLogo,
  [otp.key]: otpLogo,
  [para.key]: paraLogo,
  [paring.key]: paringLogo,
  [peaq.key]: peaqLogo,
  [pen.key]: penLogo,
  [pha.key]: phaLogo,
  [pica.key]: picaLogo,
  [pink.key]: pinkLogo,
  [ring.key]: ringLogo,
  [rmrk.key]: rmrkLogo,
  [sdn.key]: sdnLogo,
  [teer.key]: teerLogo,
  [tnkr.key]: tnkrLogo,
  [tt1.key]: tt1Logo,
  [tur.key]: turLogo,
  [stink.key]: stinkLogo,
  [sub.key]: subLogo,
  [unit.key]: ksmLogo,
  [usdc.key]: usdcLogo,
  [usdcwh.key]: usdcLogo,
  [usdt.key]: usdtLogo,
  [usdtwh.key]: usdtLogo,
  [vastr.key]: astrLogo,
  [vbnc.key]: bncLogo,
  [vdot.key]: dotLogo,
  [vfil.key]: filLogo,
  [vglmr.key]: moonbeamLogo,
  [vksm.key]: ksmLogo,
  [vmanta.key]: mantaLogo,
  [vmovr.key]: moonriverLogo,
  [wbtc.key]: wbtcLogo,
  [weth.key]: wethLogo,
  [wftm.key]: ftm,
  [wifd.key]: wifdLogo,
  [xrt.key]: xrtLogo,
  [ztg.key]: ztgLogo,
};

export const reversedLogos: Record<string, StaticImageData> = {
  [cfg.key]: cfgReversedLogo,
  [eqd.key]: eqdReversedLogo,
  [ibtc.key]: ibtcReversedLogo,
  [intr.key]: intrReversedLogo,
  [kbtc.key]: kbtcReversedLogo,
  [kint.key]: kintReversedLogo,
};
